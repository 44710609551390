import axios from "axios";

export const postRequest = async (endpoint, body = {}) => {
  try {
    // Sending POST request using axios
    const response = await axios.post(
      "https://backend-g6c0.onrender.com" + endpoint,
      {
        ...body,
        token: localStorage.getItem("token"),
      }
    );
    return response.data;
  } catch (error) {
    return;
  }
};
// eslint-disable-next-line
export default { postRequest };
